import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import SearchBar from "../../components/SearchBar";
import NavbarCustom from "../../components/Navbar";
import { Button } from "react-bootstrap";

/**
 * Renders a list of users with a search functionality.
 * @returns {JSX.Element} The UserList component.
 */
const UserList = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const userFilter = useMemo(() => {
    if (users.length === 0)
      return (
        <tr>
          <td colSpan="3">No users found</td>
        </tr>
      );

    const list = users.filter((user) => {
      const email = user.email ? user.email.toLowerCase() : "";

      if (
        user.firstname.toLowerCase().includes(search.toLowerCase()) ||
        user.lastname.toLowerCase().includes(search.toLowerCase()) ||
        email.includes(search.toLowerCase())
      ) {
        return user;
      }
    });

    if (list.length === 0)
      return (
        <tr>
          <td colSpan="3">No users found</td>
        </tr>
      );

    const renderedList =
      list.length &&
      list.map((user) => (
        <tr
          key={user.id}
          onClick={() => {
            navigate(`/admin/users/${user.id}`);
          }}
          style={{ cursor: "pointer" }}
        >
          <td>{user.firstname}</td>
          <td>{user.lastname}</td>
          <td>{user.email}</td>
        </tr>
      ));

    return renderedList;
  }, [search, users]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch("/api/users", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          throw new Error("Failed to fetch users");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div>
      <header>
        <NavbarCustom isAdmin />
      </header>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <h1>User List</h1>
        <Button
          onClick={() => {
            navigate("/admin/users/new");
          }}
        >
          Add User
        </Button>
      </div>
      <SearchBar
        label="Search Users"
        placeholder="Search by name"
        value={search}
        onChange={handleChange}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {search.length > 0
            ? userFilter
            : users.map((user) => (
                <tr
                  key={user.id}
                  onClick={() => {
                    navigate(`/admin/users/${user.id}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td>{user.email}</td>
                </tr>
              ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UserList;
