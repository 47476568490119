import React from "react";
import { Container } from "@mui/material";
import NavbarCustom from "../components/Navbar";

function Index() {
  return (
    <div>
      <header className="header">
        <NavbarCustom />
      </header>
      <Container>
        <div className="landing-body">
          <img
            src="/ImageDesktop.svg"
            alt="Synlab Logo"
            className="landing-photo-desktop"
          />
          <img
            src="/ImageMobile.svg"
            alt="Synlab Logo"
            width="80%"
            className="landing-photo-mobile"
          />

          <div className="videoText">
            <iframe
              src="https://player.vimeo.com/video/302177919?h=3099c39328"
              frameborder="0"
              width={598}
              height={280}
              className="iframe-container"
              allow="fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
            <p className="bodyText">
              The Synaesthetic Media Lab (Synlab) at Toronto Metropolitan
              University (TMU) explores emerging modalities in new media.
              Applications range across media arts, computational sciences,
              entertainment, and educational domains. Our research focuses on
              tangible and embodied interaction technologies that support
              creative practices that bridge the physical and digital worlds.
            </p>
          </div>

          <div className="hero"></div>

          <h2 className="subTitle">
            NOTICE: <br />
            WEBSITE UNDER CONSTRUCTION
          </h2>
          <p className="bodyText" style={{ width: "unset" }}>
            If you want to get more recent updates on the lab as we refresh our
            website, please follow us on social media.
          </p>

          <div className="socialMediaLogos">
            <a href="https://www.instagram.com/synlab.tmu/" target="_">
              <img
                src="/sqLogo_instagram.png"
                alt="Instagram Logo"
                className="socialMediaLogo"
              />
            </a>
            <a href="https://twitter.com/synlab_" target="_">
              <img
                src="/sqLogo_X.png"
                alt="Twitter Logo"
                className="socialMediaLogo"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/synaesthetic-media-lab/"
              target="_"
            >
              <img
                src="/sqLogo_linkedIN.png"
                alt="LinkedIn Logo"
                className="socialMediaLogo"
              />
            </a>
            <a href="https://vimeo.com/synlab" target="_">
              <img
                src="/sqLogo_vimeo.png"
                alt="Vimeo Logo"
                className="socialMediaLogo"
              />
            </a>
          </div>
        </div>
      </Container>
      <footer className="footer">
        <div className="heroWhite"></div>
        <div className="CompanyLogosDisplay">
          <img
            src="/sqLogo_TCS_cOffWhite.png"
            alt="TCS Logo"
            className="companyLogos"
          />
          <img
            src="/rectLogo_MRI.png"
            alt="MRI Logo"
            className="companyLogos"
          />
          <img
            src="/rectLogo_CFI.png"
            alt="CFI Logo"
            className="companyLogos"
          />
          <img
            src="/rectLogo_SSHRC.png"
            alt="SSHRC Logo"
            className="companyLogos"
          />
          <img
            src="/rectLogo_TMU.png"
            alt="TMU Logo"
            className="companyLogos"
          />
          <img
            src="/sqLogo_epic.png"
            alt="Epic Logo"
            className="companyLogos"
          />
          <img src="/sqLogo_NSF.png" alt="NSF Logo" className="companyLogos" />
          <img
            src="/rectLogo_NSERC.png"
            alt="NSERC Logo"
            className="companyLogos"
          />
        </div>
        <div className="heroWhite"></div>

        <h3 className="footerText">synaesthetic media lab</h3>
        <p className="sub-footer-text">
          Suite #N103, 483 Bay Street, Toronto, Ontario, Canada, M5G 2E1
          <br />
          info@synlab.ca
        </p>
      </footer>
    </div>
  );
}

export default Index;
