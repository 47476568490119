import React, { useMemo, useState } from "react";
import "./App.css";
import "./Landing.css";

//Theme
import MainTheme from "./Theme/MainTheme";
import Nav from "react-bootstrap/Nav";

//Components
import Footer from "./components/Footer/Footer";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";

//Pages
import Index from "./pages/Index";
import People from "./pages/People";
import Projects from "./pages/Projects";
import Papers from "./pages/Papers";
import PeopleIndividual from "./pages/PeopleIndividual";
import ProjectIndividual from "./pages/ProjectIndividual";
import PaperIndividual from "./pages/PaperIndividual";
import ProjectPageDev from "./pages/ProjectPageDev";

//Admin Pages
import AdminLogin from "./pages/admin/Login";
import AdminUsers from "./pages/admin/Users";
import AdminUserForm from "./pages/admin/UserForm";
import AdminPapers from "./pages/admin/Publications";
import AdminPaperForm from "./pages/admin/PaperForm";
import AdminProjects from "./pages/admin/Projects";
import AdminProjectForm from "./pages/admin/ProjectForm";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  window.addEventListener("storage", (e) => {
    if (e.key === "token") {
      setIsAuthenticated(e.newValue ? true : false);
    }
  });

  function RequireAuth({ children }) {
    if (!isAuthenticated) {
      return <Navigate to="/admin" />;
    }

    return children;
  }

  return (
    <ThemeProvider theme={MainTheme}>
      <Routes>
        //Public Routes
        <Route path="/" element={<Index />} />
        {/* <Route path="/people" element={<People />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/papers" element={<Papers />} />
          <Route path="/dev" element={<ProjectPageDev />} />
          <Route path="/people/:id" element={<PeopleIndividual />} />
          <Route path="/projects/:id" element={<ProjectIndividual />} />
          <Route path="/papers/:id" element={<PaperIndividual />} /> */}
        //Admin Routes
        <Route path="/admin" element={<AdminLogin />} />
        //Admin Routes
        <Route
          path="/admin/users"
          element={
            <Container>
              <AdminUsers />
            </Container>
          }
        />
        <Route
          path="/admin/users/:userId"
          element={
            <Container>
              <AdminUserForm />
            </Container>
          }
        />
        <Route
          path="/admin/papers"
          element={
            <Container>
              <AdminPapers />
            </Container>
          }
        />
        <Route
          path="/admin/papers/:paperId"
          element={
            <Container>
              <AdminPaperForm />
            </Container>
          }
        />
        <Route
          path="/admin/projects"
          element={
            <Container>
              <AdminProjects />
            </Container>
          }
        />
        <Route
          path="/admin/projects/:projectId"
          element={
            <Container>
              <AdminProjectForm />
            </Container>
          }
        />
        <Route
          path="/404"
          element={
            <center>
              <h1 style={{ color: "#000", fontSize: "40px" }}>404</h1>
            </center>
          }
        />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      {/* <Footer /> */}
    </ThemeProvider>
  );
}

export default App;
