import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";

import FormInput from "../../components/FormInput";
import NavbarCustom from "../../components/Navbar";
import ItemList from "../../components/ListItem";

const EditUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    title: "",
    shorttitle: "",
    startyear: new Date().getFullYear(),
    endyear: 0,
    email: "",
    url: "",
    bio: "",
    group_id: 0,
    institution_id: 0,
    photo: "",
    unlisted: 0,
    projects: [],
    publications: [],
  });

  const [file, setFile] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [userInstitutions, setUserInstitutions] = useState([]);
  const [show, setShow] = useState(false);

  //Publications
  const [publications, setPublications] = useState([]);
  const [searchPublication, setSearchPublication] = useState("");

  //Projects
  const [projects, setProjects] = useState([]);
  const [searchProject, setSearchProject] = useState("");

  const filteredPublications = useMemo(() => {
    const search = publications.filter((publication) =>
      publication.title.toLowerCase().includes(searchPublication.toLowerCase())
    );

    if (!user.publications) return search;

    const noDuplicate = search.filter(
      (publication) =>
        !user.publications.some((item) => item.id === publication.id)
    );

    return noDuplicate;
  }, [publications, searchPublication, user]);

  const filteredProjects = useMemo(() => {
    const search = projects.filter((project) =>
      project.name.toLowerCase().includes(searchProject.toLowerCase())
    );

    if (!user.projects) return search;

    const noDuplicate = search.filter(
      (project) =>
        user.projects.findIndex((item) => item.id === project.id) === -1
    );

    return noDuplicate;
  }, [projects, searchProject, user]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`/api/users/${userId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUser(() => ({
            ...data.userData[0],
            projects: data.projectData || [],
            publications: data.paperData || [],
          }));
        } else {
          navigate("/admin/users");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchGroups = async () => {
      try {
        const response = await fetch(`/api/users/groups`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserGroups(data);
        } else {
          throw new Error("Failed to fetch groups");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchInstitutions = async () => {
      try {
        const response = await fetch(`/api/users/institutions`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserInstitutions(data);
        } else {
          throw new Error("Failed to fetch institutions");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await fetch(`/api/projects`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProjects(data);
        } else {
          throw new Error("Failed to fetch projects");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPublications = async () => {
      try {
        const response = await fetch(`/api/publications`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPublications(data);
        } else {
          throw new Error("Failed to fetch publications");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (userId && userId !== "new") fetchUser();

    // Fetch groups, institutions, projects, and publications
    fetchGroups();
    fetchInstitutions();
    fetchProjects();
    fetchPublications();
  }, [userId]);

  const deleteUser = async (id) => {
    try {
      const response = await fetch(`/api/users/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        navigate("/admin/users");
      } else {
        throw new Error("Failed to delete user");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "photo") setFile(files[0]);

    setUser((prevUser) => ({
      ...prevUser,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("photo", file);
      formData.append("firstname", user.firstname);
      formData.append("lastname", user.lastname);
      formData.append("title", user.title);
      formData.append("shorttitle", user.shorttitle);
      formData.append("startyear", user.startyear);
      formData.append("endyear", user.endyear);
      formData.append("email", user.email);
      formData.append("url", user.url);
      formData.append("bio", user.bio);
      formData.append("group_id", user.group_id);
      formData.append("institution_id", user.institution_id);
      formData.append("unlisted", user.unlisted ? 1 : 0);
      formData.append("projects", JSON.stringify(user.projects));
      formData.append("publications", JSON.stringify(user.publications));

      const isUserId = userId && userId !== "new";

      const response = await fetch(
        isUserId ? `/api/users/${userId}` : `/api/users`,
        {
          method: isUserId ? "PUT" : "POST",
          body: formData,
          headers: new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }),
        }
      );

      if (response.ok) {
        navigate("/admin/users");
      } else {
        throw new Error("Failed to update user");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <header>
        <NavbarCustom isAdmin />
      </header>
      <h1>Edit User</h1>
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Container>
          <Row>
            <Col>
              <FormInput
                name="First Name"
                type="text"
                placeholder="First Name"
                label="firstname"
                value={user.firstname}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col>
              <FormInput
                name="Last Name"
                type="text"
                placeholder="Last Name"
                label="lastname"
                value={user.lastname}
                onChange={handleInputChange}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                name="Title"
                type="text"
                placeholder="Title"
                label="title"
                value={user.title}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col>
              <FormInput
                name="Short Title"
                type="text"
                placeholder="short title"
                label="shorttitle"
                value={user.shorttitle}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                name="Start Year"
                type="text"
                label="startyear"
                value={user.startyear}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col>
              <FormInput
                name="End Year (0 for current/no end year)"
                type="text"
                label="endyear"
                value={user.endyear}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                name="Bio"
                type="text"
                placeholder="Bio"
                label="bio"
                value={user.bio}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              {user.photo ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Form.Label>Image</Form.Label>
                  <div>
                    <img
                      src={`${user.photo}`}
                      alt="user photo"
                      style={{ width: "100px", height: "100px" }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="red"
                      class="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      onClick={() => {
                        setUser((prevUser) => ({
                          ...prevUser,
                          photo: "",
                        }));
                      }}
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                  </div>
                </div>
              ) : (
                <FormInput
                  name="photo"
                  type="file"
                  label="photo"
                  accept="image/*"
                  value={user.photo}
                  onChange={handleInputChange}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                name="Email"
                type="text"
                placeholder="Email"
                label="email"
                value={user.email}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <Form.Label>Group</Form.Label>
              <Form.Select
                value={user.group_id}
                onChange={(e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    group_id: e.target.value,
                  }));
                }}
              >
                {userGroups.map((group) => (
                  <option value={group.id}>{group.name}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Institution</Form.Label>
              <Form.Select
                value={user.institution_id}
                onChange={(e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    institution_id: e.target.value,
                  }));
                }}
              >
                {userInstitutions.map((group) => (
                  <option value={group.id}>{group.name}</option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <FormInput
                name="URL"
                type="text"
                placeholder="URL"
                label="url"
                value={user.url}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Form.Label>Unlisted</Form.Label>
              <InputGroup.Checkbox
                aria-label="Checkbox for unlisted"
                onChange={(e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    unlisted: e.target.checked,
                  }));
                }}
                value={user.unlisted}
                checked={user.unlisted}
              />
            </Col>
          </Row>
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <h2>Projects</h2>
            <FormInput
              name="Search for a project"
              type="text"
              placeholder="Search for a project"
              label="searchProject"
              value={searchProject}
              onChange={(e) => setSearchProject(e.target.value)}
            />

            {filteredProjects.map((project) => {
              if (searchProject === "") return null;

              return (
                <ItemList
                  key={project.id}
                  name={project.name}
                  onClick={() => {
                    project.faculty = 0;

                    setUser((prevUser) => ({
                      ...prevUser,
                      projects: prevUser.projects.concat(project),
                    }));
                  }}
                  backgroundColor="#f0f0f0"
                  isAdd
                />
              );
            })}
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              {user.projects.map((project) => (
                <ItemList
                  key={project.id}
                  name={project.name}
                  isDelete
                  onClick={() => {
                    setUser((prevUser) => ({
                      ...prevUser,
                      projects: prevUser.projects.filter(
                        (item) => item.id !== project.id
                      ),
                    }));
                  }}
                  edit={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1rem",
                          margin: 0,
                          marginLeft: "20px",
                        }}
                      >
                        Position:
                      </p>
                      <FormInput
                        type="text"
                        placeholder={0}
                        value={project.position}
                        onChange={(e) => {
                          setUser((prevUser) => ({
                            ...prevUser,
                            projects: prevUser.projects.map((item) =>
                              item.id === project.id
                                ? { ...item, position: e.target.value }
                                : item
                            ),
                          }));
                        }}
                        required
                      />
                      <p
                        style={{
                          fontSize: "1rem",
                          margin: 0,
                          marginLeft: "20px",
                        }}
                      >
                        Faculty:
                      </p>
                      <InputGroup.Checkbox
                        aria-label="Checkbox for Faculty"
                        onChange={(e) => {
                          setUser((prevUser) => ({
                            ...prevUser,
                            projects: prevUser.projects.map((item) =>
                              item.id === project.id
                                ? { ...item, faculty: e.target.checked }
                                : item
                            ),
                          }));
                        }}
                        value={project.faculty}
                        checked={project.faculty}
                      />
                    </div>
                  }
                />
              ))}
            </div>
          </div>
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <h2>Publications</h2>
            <FormInput
              name="Search for a publication"
              type="text"
              placeholder="Search for a publication"
              label="searchPublication"
              value={searchPublication}
              onChange={(e) => setSearchPublication(e.target.value)}
            />

            {filteredPublications.map((publication) => {
              if (searchPublication === "") return null;

              return (
                <ItemList
                  key={publication.id}
                  name={publication.title}
                  onClick={() => {
                    setUser((prevUser) => ({
                      ...prevUser,
                      publications: prevUser.publications.concat(publication),
                    }));
                  }}
                  backgroundColor="#f0f0f0"
                  isAdd
                />
              );
            })}
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              {user.publications.map((publication) => (
                <ItemList
                  key={publication.id}
                  name={publication.title}
                  onClick={() => {
                    setUser((prevUser) => ({
                      ...prevUser,
                      publications: prevUser.publications.filter(
                        (item) => item.id !== publication.id
                      ),
                    }));
                  }}
                  edit={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1rem",
                          margin: 0,
                          marginLeft: "20px",
                        }}
                      >
                        Position:
                      </p>
                      <FormInput
                        type="text"
                        placeholder={0}
                        value={publication.position}
                        onChange={(e) => {
                          setUser((prevUser) => ({
                            ...prevUser,
                            publications: prevUser.publications.map((item) =>
                              item.id === publication.id
                                ? { ...item, position: e.target.value }
                                : item
                            ),
                          }));
                        }}
                        required
                      />
                    </div>
                  }
                  isDelete
                />
              ))}
            </div>
          </div>
        </Container>

        <Button variant="primary" type="submit">
          Submit
        </Button>

        {userId && userId !== "new" ? (
          <Button
            variant="danger"
            onClick={() => setShow(true)}
            style={{ left: "60px", position: "relative" }}
          >
            Delete User
          </Button>
        ) : null}
      </Form>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this user? After you delete this user,
          they will not be recoverable.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteUser(userId)}>
            Delete User
          </Button>
          <Button variant="primary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditUser;
