import React from "react";
import { useEffect, useState } from "react";

//Component: People
import UserCard from "../components/UserCard";

function ProjectPageDev() {
  useEffect(() => {
    let navbar = document.getElementById("navbar");
    navbar.classList.add(".navbar-show-no-animation");
    navbar.classList.remove("navbar-none");
  }, []);

  return (
    <div className="page-development">
      <h1>project page in development! Please check back later!</h1>
    </div>
  );
}

export default ProjectPageDev;
