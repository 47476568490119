import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

//COMPONENTS
import { ReactComponent as Logo } from "./FunderLogo_PH.svg";
import { ThemeProvider } from "react-bootstrap";

function Footer() {
  const theme = useTheme();

  const StyledGrid = styled(Grid)({
    height: "auto",
    width: "100%",
    padding: "50px",
    position: "absolute",
    bottom: 0,
    backgroundColor: theme.palette.accent.main,
  });

  const StyledTypography = styled(Typography)({
    color: theme.palette.white.main,
    display: "block",
    margin: "10px",
    textAlign: "center",
    fontFamily: theme.palette.typography.fontFamily,
  });

  const Line = styled(Grid)({
    height: "1px",
    margin: "40px",
    width: "100%",
    backgroundColor: theme.palette.white.main,
  });

  return (
    <StyledGrid container direction={"row"}>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={12} sm={3}>
          <StyledTypography variant="p">Footer</StyledTypography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledTypography variant="p">Footer</StyledTypography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledTypography variant="p">Footer</StyledTypography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledTypography variant="p">Footer</StyledTypography>
        </Grid>
      </Grid>
      <Line item xs={12} />
      <Grid container justifyContent={"space-between"} spacing={2}>
        <Grid item xs={12} sm={3}>
          <Logo width="100%" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Logo width="100%" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Logo width="100%" />
        </Grid>
      </Grid>
      <Line item xs={12} />
      <Grid container direction={"column"} justifyContent={"center"}>
        <StyledTypography variant="h4" component={"p"}>
          synaesthetic media lab
        </StyledTypography>
        <StyledTypography variant="p">
          Suite # N103, 483 Bay Street, Toronto, Ontario, Canada M5G 2E1
        </StyledTypography>
        <StyledTypography variant="p">info@synlab.ca</StyledTypography>
      </Grid>
    </StyledGrid>
  );
}

export default Footer;
